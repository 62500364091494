<template>
  <div>
    <!-- <vue-good-table 
      :columns="columns" 
      :rows="rows" 
      @on-row-click="onRowClick" 
      @on-selected-rows-change="selectionChanged" 
      :search-options="{ 
        enabled: true,
        multipleColumns: true,
        initialSortBy: [
          {field: 'station', type: 'asc'},
          {field: 'name_file', type: 'asc'}
        ]}" 
      :pagination-options="{ enabled: true }">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'station'" style="display: block; white-space: nowrap">
          {{ props.row[props.column.field] }}
        </div>
        <div v-else style="display: block; text-align: center; white-space: nowrap">
          {{ props.row[props.column.field] }}
        </div>
      </template>
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field !='station' && props.column.field !='name_file' && props.column.field !='percent'">
          <div>
            {{props.column.field.split('_')[0]}}
          </div>
          <hr class="solid">
          <div>
            {{props.column.field.split('_')[1]}}
          </div>
        </span>
      </template>
    </vue-good-table> -->
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">
        <b-form-group
          label=""
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        ></b-form-group>
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
    <b-table striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage" :fields="columns" :items="rows" :filter="filter" @filtered="onFiltered" >
      <template #thead-top="data">
        <b-tr>
          <b-th colspan="1" class="my-header-left-border"></b-th>
          <b-th variant="secondary"></b-th>
          <b-th v-for="n in 31" :key="n" variant="primary" colspan="2" class="my-header-left-border text-center">
              <div class="text-center">{{ padZero(n,2) }}</div>
          </b-th>
          <b-th variant="secondary" class="my-header-left-border my-right-border"></b-th>
          <!-- <b-th variant="primary" colspan="2">01</b-th>
          <b-th variant="primary" colspan="2">02</b-th> -->
        </b-tr>
      </template>
      <template #head(name_file)="scope">
        <div class="text-nowrap">{{scope.label}}</div>
      </template>
      <!-- <template v-for="n in 31" v-slot:[`head(${padZero(n,2)}+_00)`]="scope">
        <div class="text-center text-nowrap">{{scope.label}}</div>
      </template> -->
      <template v-for="n in 31" v-slot:[`head(${padZero(n,2)}_00)`]="scope">
        <div class="text-center text-nowrap">{{scope.label}}</div>
      </template>
      <template v-for="n in 31" v-slot:[`head(${padZero(n,2)}_12)`]="scope">
        <div class="text-center text-nowrap">{{scope.label}}</div>
      </template>
      <template v-for="n in 31" v-slot:[`cell(${padZero(n,2)}_00)`]="scope">
        <div class="text-center text-nowrap">{{scope.item[`${padZero(n,2)}_00`]}}</div>
      </template>
      <template v-for="n in 31" v-slot:[`cell(${padZero(n,2)}_12)`]="scope">
        <div class="text-center text-nowrap">{{scope.item[`${padZero(n,2)}_12`]}}</div>
      </template>
      <template #head(station)="scope">
        <div class="text-center text-nowrap">{{scope.label}}</div>
      </template>
      <template #cell(station)="data">
        <div class="text-nowrap">{{ data.item.station }}</div>
      </template>
      <template #head(percent)="scope">
        <div class="text-center text-nowrap">{{scope.label}}</div>
      </template>
      <template #cell(percent)="data">
        <div class="text-center text-nowrap">{{ data.item.percent }}</div>
      </template>
      <template #cell(name_file)="data">
        <div class="text-nowrap text-center">{{ data.item.name_file }}</div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <div style="padding-top: 10px; padding-bottom: 10px; text-align: right">
    </div>
    <div>
      <b-button style="padding-top: 10px; padding-bottom: 10px" @click="asexportExcel" class="btn btn-success"
        :sumberdata="sumberdata">Download</b-button>
    </div>
    <!-- <a-button @click="cekdata" class="btn-success">dsadas</a-button> -->
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BListGroup,
  BListGroupItem,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BTabs,
  BTab,
  BFormDatepicker,
  BAvatar,
  BBadge,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTable,
  BTr,
  BTh
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
// import HelloWorld from "./HelloWorld.vue";
import moment from "moment";
moment.locale("id");

export default {
  name: "DinamicTable",
  props: {
    DATAS: Object,
    PERIODE: Object,
    ExportExcelName: String,
    // PRODUK: Object,
    RESOURCE: Object,
  },
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    VueGoodTable,
    saveExcel,
    // HelloWorld,
    ExcelJS,
    saveAs,
    BTable,
    BTr,
    BTh
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],

      columns: [],
      rows: [],
      searchTerm: "",
      export_name: this.ExportExcelName,
      export_xlsx: this.ExportExcelName + ".xlsx",
      field_xlsx: {},
      balaidata: [],
      sumberdata: [],
    }
  },
  mounted() {
    console.log("this.rows.length", this.rows.length);
    // console.log("PERIODE.PERIODE", this.PERIODE.substring(0, 4))
    // console.log("0000000000DATAS  00000", this.DATE_SELECTED);
    // console.log("route))))", this.$route.path);
    this.totalRows = this.rows.length
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    padZero(val, n) {
			return `${"0".repeat(n)}${val}`.substr(-n, n);
		},
    onFiltered(filteredItems) {
      console.log('onFiltered', filteredItems)
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    callParent() {
      this.$emit("onAction", { type: "edit" });
    },
    onRowClick(params) {
      this.$emit("onRowClick", params);
    },
    selectionChanged(params) {
      this.$emit("selectionChanged", params);
    },
    refreshDataChild(message) {
      this.columns = message.columns;
      this.rows = message.DATAS;
      // this.sumberdata = message.RESOURCE;
      // this.balaidata = message.BALAI;
      this.totalRows = this.rows.length
    },
    generateTable: function generateTable(message) {
      
      this.columns = message.columns;
      this.rows = message.DATAS;
      // this.sumberdata = message.RESOURCE;
      // this.balaidata = message.BALAI;
      this.perPage = 10
      this.currentPage = 1
      this.totalRows = this.rows.length
      console.log('generateTable', this.totalRows)
    },
    onExportExcel() {
      var columnCustome = [];
      for (var i in this.columns) {
        var infoCol = this.columns[i];
        columnCustome.push({
          field: infoCol.field,
          title: infoCol.label,
        });
      }
      saveExcel({
        data: this.rows,
        fileName: this.export_name,
        columns: columnCustome,
      });
    },
    cekdata() {
      console.log("filed : ", this.field_xlsx);
    },
    moment,

    asexportExcel() {

      const workbook = new ExcelJS.Workbook();
      const filename = "sheet";

      let worksheet = workbook.addWorksheet(filename, {
        
      });
      // workbook.xlsx.writeFile("fileName");

      worksheet.getCell("A6:A7").alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.mergeCells("A6:A7");
      const cellStationId = worksheet.getCell("A6");
      cellStationId.value = "Stasiun";

      worksheet.mergeCells("B6:B7");
      worksheet.getCell("B6:B7").alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      const cellStationName = worksheet.getCell("B6");
      cellStationName.value = "Nama File";

      
      /**
       * start*
      **/

      const headerRowHasil = worksheet.getRow(1);
      headerRowHasil.height = 30;
      worksheet.mergeCells("A1:AH1");    
      headerRowHasil.getCell(1).value = "Hasil Monitoring Data RASON  " + moment(this.PERIODE[0]).format("DD MMMM  YYYY") +" - " + moment(this.PERIODE[1]).format("DD MMMM  YYYY");
      headerRowHasil.getCell(1).font = {
        name: "Arial Black",
        size: 16,
        bold: true,
        color: "#000",
      };
      headerRowHasil.getCell(1).alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      // const cellTanggal = worksheet.getCell("C6");
      // worksheet.mergeCells("C6:AG6");
      // worksheet.getCell("C6:AG6").alignment = {
      //   horizontal: "center",
      //   vertical: "middle",
      // };

      // cellTanggal.value = "Tanggal";
      // worksheet.getRow(6).eachCell((cell) =>
      //   Object.assign(cell, {
      //     border: {
      //       top: { style: "thin" },
      //       left: { style: "thin" },
      //       bottom: { style: "thin" },
      //       right: { style: "thin" },
      //     },
      //   })
      // );

      const cellTanggalM1 = worksheet.getCell("C6");
      cellTanggalM1.value = "1"
      worksheet.getCell("C6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("C6:D6");
      worksheet.getCell("C6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal1_00 = worksheet.getCell("C7");
      worksheet.getCell("C7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("C7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      const cellTanggal1_12 = worksheet.getCell("D7");
      worksheet.getCell("D7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("D7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM2 = worksheet.getCell("E6");
      cellTanggalM2.value = "2"
      worksheet.getCell("E6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("E6:F6");
      worksheet.getCell("E6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal2_00 = worksheet.getCell("E7");
      worksheet.getCell("E7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("E7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal2_12 = worksheet.getCell("F7");
      worksheet.getCell("F7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("F7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM3 = worksheet.getCell("G6");
      cellTanggalM3.value = "3"
      worksheet.getCell("G6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("G6:H6");
      worksheet.getCell("G6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal3_00 = worksheet.getCell("G7");
      worksheet.getCell("G7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("G7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal3_12 = worksheet.getCell("H7");
      worksheet.getCell("H7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("H7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM4 = worksheet.getCell("I6");
      cellTanggalM4.value = "4"
      worksheet.getCell("I6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("I6:J6");
      worksheet.getCell("I6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal4_00 = worksheet.getCell("I7");
      worksheet.getCell("I7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("I7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal4_12 = worksheet.getCell("J7");
      worksheet.getCell("J7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("J7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM5 = worksheet.getCell("K6");
      cellTanggalM5.value = "5"
      worksheet.getCell("K6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("K6:L6");
      worksheet.getCell("K6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal5_00 = worksheet.getCell("K7");
      worksheet.getCell("K7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("K7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal5_12 = worksheet.getCell("L7");
      worksheet.getCell("L7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("L7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM6 = worksheet.getCell("M6");
      cellTanggalM6.value = "6"
      worksheet.getCell("M6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("M6:N6");
      worksheet.getCell("M6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal6_00 = worksheet.getCell("M7");
      worksheet.getCell("M7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("M7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal6_12 = worksheet.getCell("N7");
      worksheet.getCell("N7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("N7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM7 = worksheet.getCell("O6");
      cellTanggalM7.value = "7"
      worksheet.getCell("O6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("O6:P6");
      worksheet.getCell("O6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal7_00 = worksheet.getCell("O7");
      worksheet.getCell("O7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("O7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal7_12 = worksheet.getCell("P7");
      worksheet.getCell("P7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("P7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM8 = worksheet.getCell("Q6");
      cellTanggalM8.value = "8"
      worksheet.getCell("Q6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("Q6:R6");
      worksheet.getCell("Q6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal8_00 = worksheet.getCell("Q7");
      worksheet.getCell("Q7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("Q7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal8_12 = worksheet.getCell("R7");
      worksheet.getCell("R7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("R7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM9 = worksheet.getCell("S6");
      cellTanggalM9.value = "9"
      worksheet.getCell("S6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("S6:T6");
      worksheet.getCell("S6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal9_00 = worksheet.getCell("S7");
      worksheet.getCell("S7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("S7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal9_12 = worksheet.getCell("T7");
      worksheet.getCell("T7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("T7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM10 = worksheet.getCell("U6");
      cellTanggalM10.value = "10"
      worksheet.getCell("U6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("U6:V6");
      worksheet.getCell("U6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal10_00 = worksheet.getCell("U7");
      worksheet.getCell("U7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("U7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal10_12 = worksheet.getCell("V7");
      worksheet.getCell("V7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("V7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM11 = worksheet.getCell("W6");
      cellTanggalM11.value = "11"
      worksheet.getCell("W6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("W6:X6");
      worksheet.getCell("W6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      const cellTanggal11_00 = worksheet.getCell("W7");
      worksheet.getCell("W7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("W7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal11_12 = worksheet.getCell("X7");
      worksheet.getCell("X7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("X7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM12 = worksheet.getCell("Y6");
      cellTanggalM12.value = "12"
      worksheet.getCell("Y6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("Y6:Z6");
      worksheet.getCell("Y6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal12_00 = worksheet.getCell("Y7");
      worksheet.getCell("Y7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("Y7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal12_12 = worksheet.getCell("Z7");
      worksheet.getCell("Z7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("Z7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM13 = worksheet.getCell("AA6");
      cellTanggalM13.value = "13"
      worksheet.getCell("AA6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AA6:AB6");
      worksheet.getCell("AA6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      
      const cellTanggal13_00 = worksheet.getCell("AA7");
      worksheet.getCell("AA7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AA7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal13_12 = worksheet.getCell("AB7");
      worksheet.getCell("AB7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AB7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM14 = worksheet.getCell("AC6");
      cellTanggalM14.value = "14"
      worksheet.getCell("AC6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AC6:AD6");
      worksheet.getCell("AC6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal14_00 = worksheet.getCell("AC7");
      worksheet.getCell("AC7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AC7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal14_12 = worksheet.getCell("AD7");
      worksheet.getCell("AD7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AD7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM15 = worksheet.getCell("AE6");
      cellTanggalM15.value = "15"
      worksheet.getCell("AE6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AE6:AF6");
      worksheet.getCell("AE6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal15_00 = worksheet.getCell("AE7");
      worksheet.getCell("AE7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AE7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal15_12 = worksheet.getCell("AF7");
      worksheet.getCell("AF7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AF7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM16 = worksheet.getCell("AG6");
      cellTanggalM16.value = "16"
      worksheet.getCell("AG6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AG6:AH6");
      worksheet.getCell("AG6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal16_00 = worksheet.getCell("AG7");
      worksheet.getCell("AG7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AG7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal16_12 = worksheet.getCell("AH7");
      worksheet.getCell("AH7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AH7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM17 = worksheet.getCell("AI6");
      cellTanggalM17.value = "17"
      worksheet.getCell("AI6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AI6:AJ6");
      worksheet.getCell("AI6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal17_00 = worksheet.getCell("AI7");
      worksheet.getCell("AI7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AI7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal17_12 = worksheet.getCell("AJ7");
      worksheet.getCell("AJ7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AJ7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM18 = worksheet.getCell("AK6");
      cellTanggalM18.value = "18"
      worksheet.getCell("AK6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AK6:AL6");
      worksheet.getCell("AK6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal18_00 = worksheet.getCell("AK7");
      worksheet.getCell("AK7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AK7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal18_12 = worksheet.getCell("AL7");
      worksheet.getCell("AL7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AL7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM19 = worksheet.getCell("AM6");
      cellTanggalM19.value = "19"
      worksheet.getCell("AK6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AM6:AN6");
      worksheet.getCell("AM6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal19_00 = worksheet.getCell("AM7");
      worksheet.getCell("AM7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AM7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal19_12 = worksheet.getCell("AN7");
      worksheet.getCell("AN7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AN7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM20 = worksheet.getCell("AO6");
      cellTanggalM20.value = "20"
      worksheet.getCell("AO6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AO6:AP6");
      worksheet.getCell("AO6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal20_00 = worksheet.getCell("AO7");
      worksheet.getCell("AO7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AO7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal20_12 = worksheet.getCell("AP7");
      worksheet.getCell("AP7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AP7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM21 = worksheet.getCell("AQ6");
      cellTanggalM21.value = "21"
      worksheet.getCell("AQ6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AQ6:AR6");
      worksheet.getCell("AQ6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      const cellTanggal21_00 = worksheet.getCell("AQ7");
      worksheet.getCell("AQ7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AQ7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal21_12 = worksheet.getCell("AR7");
      worksheet.getCell("AR7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AR7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM22 = worksheet.getCell("AS6");
      cellTanggalM22.value = "22"
      worksheet.getCell("AS6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AS6:AT6");
      worksheet.getCell("AS6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal22_00 = worksheet.getCell("AS7");
      worksheet.getCell("AS7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AS7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal22_12 = worksheet.getCell("AT7");
      worksheet.getCell("AT7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AT7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM23 = worksheet.getCell("AU6");
      cellTanggalM23.value = "23"
      worksheet.getCell("AU6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AU6:AV6");
      worksheet.getCell("AU6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal23_00 = worksheet.getCell("AU7");
      worksheet.getCell("AU7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AU7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal23_12 = worksheet.getCell("AV7");
      worksheet.getCell("AV7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AV7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM24 = worksheet.getCell("AW6");
      cellTanggalM24.value = "24"
      worksheet.getCell("AW6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AW6:AX6");
      worksheet.getCell("AW6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      const cellTanggal24_00 = worksheet.getCell("AW7");
      worksheet.getCell("AW7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AW7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal24_12 = worksheet.getCell("AX7");
      worksheet.getCell("AX7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AX7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM25 = worksheet.getCell("AY6");
      cellTanggalM25.value = "25"
      worksheet.getCell("AY6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("AY6:AZ6");
      worksheet.getCell("AY6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      const cellTanggal25_00 = worksheet.getCell("AY7");
      worksheet.getCell("AY7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AY7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal25_12 = worksheet.getCell("AZ7");
      worksheet.getCell("AZ7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AZ7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM26 = worksheet.getCell("BA6");
      cellTanggalM26.value = "26"
      worksheet.getCell("BA6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("BA6:BB6");
      worksheet.getCell("BA6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      const cellTanggal26_00 = worksheet.getCell("BA7");
      worksheet.getCell("BA7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("BA7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal26_12 = worksheet.getCell("BB7");
      worksheet.getCell("BB7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("BB7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM27 = worksheet.getCell("BC6");
      cellTanggalM27.value = "27"
      worksheet.getCell("BC6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("BC6:BD6");
      worksheet.getCell("BC6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      const cellTanggal27_00 = worksheet.getCell("BC7");
      worksheet.getCell("BC7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("BC7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal27_12 = worksheet.getCell("BD7");
      worksheet.getCell("BD7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("BD7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM28 = worksheet.getCell("BE6");
      cellTanggalM28.value = "28"
      worksheet.getCell("BE6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("BE6:BF6");
      worksheet.getCell("BE6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      const cellTanggal28_00 = worksheet.getCell("BE7");
      worksheet.getCell("BE7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("BE7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal28_12 = worksheet.getCell("BF7");
      worksheet.getCell("BF7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("BF7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM29 = worksheet.getCell("BG6");
      cellTanggalM29.value = "29"
      worksheet.getCell("BG6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("BG6:BH6");
      worksheet.getCell("BG6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      const cellTanggal29_00 = worksheet.getCell("BG7");
      worksheet.getCell("BG7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("BG7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal29_12 = worksheet.getCell("BH7");
      worksheet.getCell("BH7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("BH7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM30 = worksheet.getCell("BI6");
      cellTanggalM30.value = "30"
      worksheet.getCell("BI6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("BI6:BJ6");
      worksheet.getCell("BI6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      const cellTanggal30_00 = worksheet.getCell("BI7");
      worksheet.getCell("BI7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("BI7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal30_12 = worksheet.getCell("BJ7");
      worksheet.getCell("BJ7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("BJ7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggalM31 = worksheet.getCell("BK6");
      cellTanggalM31.value = "31"
      worksheet.getCell("BK6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.mergeCells("BK6:BL6");
      worksheet.getCell("BK6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      const cellTanggal31_00 = worksheet.getCell("BK7");
      worksheet.getCell("BK7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("BK7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal31_12 = worksheet.getCell("BL7");
      worksheet.getCell("BL7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("BL7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      worksheet.getRow(6).eachCell((cell) =>
        Object.assign(cell, {
          border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          },
        })
      );

      
      worksheet.getRow(7).eachCell((cell) =>
        Object.assign(cell, {
          border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          },
        })
      );
      worksheet.getCell("BM6:BM7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.mergeCells("BM6:BM7");
      worksheet.getCell("BM6:BM7").alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.getCell("BM6").value = "%";
      
      worksheet.columns = [
        { key: "station", width: 45, alignment: "center", wrapText: true },
        { key: "name_file", width: 20, wrapText: true },

        { key: "01_00", width: 7 },
        { key: "01_12", width: 7 },
        { key: "02_00", width: 7 },
        { key: "02_12", width: 7 },
        { key: "03_00", width: 7 },
        { key: "03_12", width: 7 },
        { key: "04_00", width: 7 },
        { key: "04_12", width: 7 },
        { key: "05_00", width: 7 },
        { key: "05_12", width: 7 },
        { key: "06_00", width: 7 },
        { key: "06_12", width: 7 },
        { key: "07_00", width: 7 },
        { key: "07_12", width: 7 },
        { key: "08_00", width: 7 },
        { key: "08_12", width: 7 },
        { key: "09_00", width: 7 },
        { key: "09_12", width: 7 },
        { key: "10_00", width: 7 },
        { key: "10_12", width: 7 },
        { key: "11_00", width: 7 },
        { key: "11_12", width: 7 },
        { key: "12_00", width: 7 },
        { key: "12_12", width: 7 },
        { key: "13_00", width: 7 },
        { key: "13_12", width: 7 },
        { key: "14_00", width: 7 },
        { key: "14_12", width: 7 },
        { key: "15_00", width: 7 },
        { key: "15_12", width: 7 },
        { key: "16_00", width: 7 },
        { key: "16_12", width: 7 },
        { key: "17_00", width: 7 },
        { key: "17_12", width: 7 },
        { key: "18_00", width: 7 },
        { key: "18_12", width: 7 },
        { key: "19_00", width: 7 },
        { key: "19_12", width: 7 },
        { key: "20_00", width: 7 },
        { key: "20_12", width: 7 },
        { key: "21_00", width: 7 },
        { key: "21_12", width: 7 },
        { key: "22_00", width: 7 },
        { key: "22_12", width: 7 },
        { key: "23_00", width: 7 },
        { key: "23_12", width: 7 },
        { key: "24_00", width: 7 },
        { key: "24_12", width: 7 },
        { key: "25_00", width: 7 },
        { key: "25_12", width: 7 },
        { key: "26_00", width: 7 },
        { key: "26_12", width: 7 },
        { key: "27_00", width: 7 },
        { key: "27_12", width: 7 },
        { key: "28_00", width: 7 },
        { key: "28_12", width: 7 },
        { key: "29_00", width: 7 },
        { key: "29_12", width: 7 },
        { key: "30_00", width: 7 },
        { key: "30_12", width: 7 },
        { key: "31_00", width: 7 },
        { key: "31_12", width: 7 },
        { key: "percent", width: 10, style: { numFmt: '#,#0.00"%"' } },
      ];

      worksheet.addRows(this.rows);
      const rowCount = worksheet.rowCount;
      for (let i = 6; i <= rowCount; i++) {
        const row = worksheet.getRow(7 + i);
        const data = worksheet.getRow(i);

        for (let index = 1; index <= 65; index++) {
          data.getCell(index).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };

          data.getCell(index).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        }
      }

      worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };

      worksheet.getCell(`A${rowCount}`).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.mergeCells(`A${rowCount + 4}:B${rowCount + 4}`);
      worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";
      worksheet.getCell(`A${rowCount + 4}`).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.mergeCells(`A${rowCount + 5}:B${rowCount + 5}`);
      worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
      worksheet.getCell(`A${rowCount + 5}`).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.mergeCells(`A${rowCount + 6}:B${rowCount + 6}`);
      worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
      worksheet.getCell(`A${rowCount + 6}`).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };

      worksheet.getColumn(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.getColumn(3).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.getColumn(4).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };

      worksheet.mergeCells(`AC${rowCount + 5}:AF${rowCount + 5}`);
      worksheet.getCell(`AC${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
      worksheet.getCell(`AC${rowCount + 5}`).alignment = {
        horizontal: "left",
        vertical: "middle",
        wrapText: true,
      };

      worksheet.mergeCells(`AC${rowCount + 6}:AF${rowCount + 6}`);
      worksheet.getCell(`AC${rowCount + 6}`).value = "Pembuat Laporan, ";
      worksheet.getCell(`AC${rowCount + 6}`).alignment = {
        horizontal: "left",
        vertical: "middle",
        wrapText: true,
      };

      cellTanggal1_00.value = "00";
      cellTanggal1_12.value = "12";
      cellTanggal2_00.value = "00";
      cellTanggal2_12.value = "12";
      cellTanggal3_00.value = "00";
      cellTanggal3_12.value = "12";
      cellTanggal4_00.value = "00";
      cellTanggal4_12.value = "12";
      cellTanggal5_00.value = "00";
      cellTanggal5_12.value = "12";
      cellTanggal6_00.value = "00";
      cellTanggal6_12.value = "12";
      cellTanggal7_00.value = "00";
      cellTanggal7_12.value = "12";
      cellTanggal8_00.value = "00";
      cellTanggal8_12.value = "12";
      cellTanggal9_00.value = "00";
      cellTanggal9_12.value = "12";
      cellTanggal10_00.value = "00";
      cellTanggal10_12.value = "12";
      cellTanggal11_00.value = "00";
      cellTanggal11_12.value = "12";
      cellTanggal12_00.value = "00";
      cellTanggal12_12.value = "12";
      cellTanggal13_00.value = "00";
      cellTanggal13_12.value = "12";
      cellTanggal14_00.value = "00";
      cellTanggal14_12.value = "12";
      cellTanggal15_00.value = "00";
      cellTanggal15_12.value = "12";
      cellTanggal16_00.value = "00";
      cellTanggal16_12.value = "12";
      cellTanggal17_00.value = "00";
      cellTanggal17_12.value = "12";
      cellTanggal18_00.value = "00";
      cellTanggal18_12.value = "12";
      cellTanggal19_00.value = "00";
      cellTanggal19_12.value = "12";
      cellTanggal20_00.value = "00";
      cellTanggal20_12.value = "12";
      cellTanggal21_00.value = "00";
      cellTanggal21_12.value = "12";
      cellTanggal22_00.value = "00";
      cellTanggal22_12.value = "12";
      cellTanggal23_00.value = "00";
      cellTanggal23_12.value = "12";
      cellTanggal24_00.value = "00";
      cellTanggal24_12.value = "12";
      cellTanggal25_00.value = "00";
      cellTanggal25_12.value = "12";
      cellTanggal26_00.value = "00";
      cellTanggal26_12.value = "12";
      cellTanggal27_00.value = "00";
      cellTanggal27_12.value = "12";
      cellTanggal28_00.value = "00";
      cellTanggal28_12.value = "12";
      cellTanggal29_00.value = "00";
      cellTanggal29_12.value = "12";
      cellTanggal30_00.value = "00";
      cellTanggal30_12.value = "12";
      cellTanggal31_00.value = "00";
      cellTanggal31_12.value = "12";
      /**
       * FOOTER *
       * */
      

      // // FOOTRER
      workbook.xlsx
        .writeBuffer()
        .then((buffer) => saveAs(new Blob([buffer]), `${this.export_name}.xlsx`))
        .catch((err) => console.log("Error writing excel export", err));
    },
  },
  // data: function () {
  //   return {
  //     columns: [],
  //     rows: [],
  //     searchTerm: "",
  //     export_name: this.ExportExcelName,
  //     export_xlsx: this.ExportExcelName + ".xlsx",
  //     field_xlsx: {},
  //     balaidata: [],
  //     sumberdata: [],
  //   };
  // },
  watch: {
    columns(val) {
      var columnCustome = {};
      for (var i in val) {
        var infoCol = val[i];
        var label = String(infoCol.label);
        if (infoCol.field == "progress") {
          label = "Progress %";
        }
        if (infoCol.field != "station_id" && infoCol.field != "station_name" && infoCol.field != "periode" && infoCol.field != "progress") {
          columnCustome["'" + label + "'"] = infoCol.field;
        } else {
          columnCustome[label] = infoCol.field;
        }
      }
      this.field_xlsx = columnCustome;
      // var json_fields = {}

      //     var len_data = val.length;
      //     // day 28 = 32
      //     if(len_data < 33){
      //         json_fields = {
      //             "Station ID": "station_id",
      //             "Station Name": "station_name",
      //             "Periode": "periode",
      //             "01": "01",
      //             "02": "02",
      //             "03": "03",
      //             "04": "04",
      //             "05": "05",
      //             "06": "06",
      //             "07": "07",
      //             "08": "08",
      //             "09": "09",
      //             "10": "10",
      //             "11": "11",
      //             "12": "12",
      //             "13": "13",
      //             "14": "14",
      //             "15": "15",
      //             "16": "16",
      //             "17": "17",
      //             "18": "18",
      //             "19": "19",
      //             "20": "20",
      //             "21": "21",
      //             "22": "22",
      //             "23": "23",
      //             "24": "24",
      //             "25": "25",
      //             "26": "26",
      //             "27": "27",
      //             "28": "28",
      //             "Progress %": "progress"
      //         }
      //     // day 29 = 33
      //     }else if(len_data < 34){
      //         json_fields = {
      //             "Station ID": "station_id",
      //             "Station Name": "station_name",
      //             "Periode": "periode",
      //             "01": "01",
      //             "02": "02",
      //             "03": "03",
      //             "04": "04",
      //             "05": "05",
      //             "06": "06",
      //             "07": "07",
      //             "08": "08",
      //             "09": "09",
      //             "10": "10",
      //             "11": "11",
      //             "12": "12",
      //             "13": "13",
      //             "14": "14",
      //             "15": "15",
      //             "16": "16",
      //             "17": "17",
      //             "18": "18",
      //             "19": "19",
      //             "20": "20",
      //             "21": "21",
      //             "22": "22",
      //             "23": "23",
      //             "24": "24",
      //             "25": "25",
      //             "26": "26",
      //             "27": "27",
      //             "28": "28",
      //             "29": "29",
      //             "Progress %": "progress"
      //         }

      //     // day 30 = 34
      //     }else if(len_data < 35){
      //         json_fields = {
      //             "Station ID": "station_id",
      //             "Station Name": "station_name",
      //             "Periode": "periode",
      //             "01": "01",
      //             "02": "02",
      //             "03": "03",
      //             "04": "04",
      //             "05": "05",
      //             "06": "06",
      //             "07": "07",
      //             "08": "08",
      //             "09": "09",
      //             "10": "10",
      //             "11": "11",
      //             "12": "12",
      //             "13": "13",
      //             "14": "14",
      //             "15": "15",
      //             "16": "16",
      //             "17": "17",
      //             "18": "18",
      //             "19": "19",
      //             "20": "20",
      //             "21": "21",
      //             "22": "22",
      //             "23": "23",
      //             "24": "24",
      //             "25": "25",
      //             "26": "26",
      //             "27": "27",
      //             "28": "28",
      //             "29": "29",
      //             "30": "30",
      //             "Progress %": "progress"
      //         }

      //     // day 31 = 35
      //     }else if(len_data < 36){
      //         json_fields = {
      //             "Station ID": "station_id",
      //             "Station Name": "station_name",
      //             "Periode": "periode",
      //             "01": "01",
      //             "02": "02",
      //             "03": "03",
      //             "04": "04",
      //             "05": "05",
      //             "06": "06",
      //             "07": "07",
      //             "08": "08",
      //             "09": "09",
      //             "10": "10",
      //             "11": "11",
      //             "12": "12",
      //             "13": "13",
      //             "14": "14",
      //             "15": "15",
      //             "16": "16",
      //             "17": "17",
      //             "18": "18",
      //             "19": "19",
      //             "20": "20",
      //             "21": "21",
      //             "22": "22",
      //             "23": "23",
      //             "24": "24",
      //             "25": "25",
      //             "26": "26",
      //             "27": "27",
      //             "28": "28",
      //             "29": "29",
      //             "30": "30",
      //             "31": "31",
      //             "Progress %": "progress"
      //         }
      //     }
      //     console.log("JSON : ",json_fields);
      //    this.field_xlsx = json_fields;
    },
  },
};
</script>

<style>
.icons-visual {
  font-size: 15px !important;
  display: block;
  text-align: center;
  font-weight: bold;
  margin: 5px -15px 8px 0px;
}

.monitor table.vgt-table td {
  padding: 10px !important;
  vertical-align: inherit !important;
}

.monitor .vgt-left-align {
  padding: 10px !important;
  vertical-align: inherit !important;
}

.monitor table.vgt-table {
  font-size: 12px !important;
}

.b-table {
  border-bottom: 1px solid #d9d9db;
}

.b-table .my-header-left-border {
  border-left: 1px solid #d9d9db;
}

.b-table .my-left-border {
  border-left: 1px solid #d9d9db;
}

.b-table .my-right-border {
  border-right: 1px solid #d9d9db;
}

/* button {
  padding: 10px 20px;
  border: 1px solid #ddd;
  color: #333;
  background-color: green;
  border-radius: 4px;
  font-size: 24px;
  font-weight: bold;
  font-family: "微软雅黑", arail;
  cursor: pointer;
} */
</style>
