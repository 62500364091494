<template>
  <div>
    <vue-good-table class="monitor" :columns="columns" :rows="rows" @on-row-click="onRowClick" @on-selected-rows-change="selectionChanged" :search-options="{ enabled: true,}" :pagination-options="{ enabled: true }">
      <!-- <template slot="table-row" slot-scope="props">
        <div v-if="props.column.renderFlag == false && props.column.field != 'progress'"
          style="display: block; text-align: center; white-space: nowrap">
          {{ props.row[props.column.field] }}
        </div>
        <div v-if="props.column.field == 'progress'">{{ props.row[props.column.field] }}%</div>
        <div v-if="props.column.renderFlag == true && props.column.field != 'progress'" class="icons-visual">
          <a-icon type="check" v-if="props.row[props.column.field] == '1'" style="color: #00ff00" />
          <a-icon type="close" v-if="props.row[props.column.field] == '0'" style="color: #ff0000" />
          <a-icon type="stop" v-if="props.row[props.column.field] === '-'" />
        </div>
      </template> -->
    </vue-good-table>
    <div style="padding-top: 10px; padding-bottom: 10px; text-align: right">
      <!-- <a-button class="btn-success" icon="download" @click="onExportExcel">
                Export Excel
            </a-button> -->
      <!-- <download-excel
        class="btn btn-success"
        :data="rows"
        :fields="field_xlsx"
        :worksheet="export_name"
        :name="export_name"
        :header="export_name"
      >
        Download
      </download-excel> -->

      <!-- <ExcelComponent
                class="btn btn-success"
                :data="rows"
                :fields="field_xlsx"
                :worksheet="export_name"
                :name="export_name"
                :header="export_name"
                type="xls"
            >
                Download
            </ExcelComponent> -->
    </div>
    <div>
      <b-button style="padding-top: 10px; padding-bottom: 10px" @click="asexportExcel" class="btn btn-success"
        :sumberdata="sumberdata">Download</b-button>
      <!-- <HelloWorld /> -->
    </div>
    <!-- <a-button @click="cekdata" class="btn-success">dsadas</a-button> -->
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BListGroup,
  BListGroupItem,
  BCard,
  BCardHeader,
  BCardTitle,
  BTabs,
  BTab,
  BFormDatepicker,
  BAvatar,
  BBadge,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
// import HelloWorld from "./HelloWorld.vue";
import moment from "moment";
moment.locale("id");

export default {
  name: "DinamicTable",
  props: {
    DATAS: Object,
    PERIODE: Object,
    ExportExcelName: String,
    PRODUK: Object,
    RESOURCE: Object,
  },
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormGroup,
    VueGoodTable,
    saveExcel,
    // HelloWorld,
    ExcelJS,
    saveAs,
  },
  mounted() {
    // console.log("this.columns", this.columns);
    // console.log("PERIODE.PERIODE", this.PERIODE.substring(0, 4))
    // console.log("0000000000DATAS  00000", this.DATE_SELECTED);
    // console.log("route))))", this.$route.path);
  },
  methods: {
    callParent() {
      this.$emit("onAction", { type: "edit" });
    },
    onRowClick(params) {
      this.$emit("onRowClick", params);
    },
    selectionChanged(params) {
      this.$emit("selectionChanged", params);
    },
    refreshDataChild(message) {
      this.columns = message.columns;
      this.rows = message.DATAS;
      // this.sumberdata = message.RESOURCE;
      // this.balaidata = message.BALAI;
    },
    generateTable: function generateTable(message) {
      this.columns = message.columns;
      this.rows = message.DATAS;
      // this.sumberdata = message.RESOURCE;
      // this.balaidata = message.BALAI;
    },
    onExportExcel() {
      var columnCustome = [];
      for (var i in this.columns) {
        var infoCol = this.columns[i];
        columnCustome.push({
          field: infoCol.field,
          title: infoCol.label,
        });
      }
      saveExcel({
        data: this.rows,
        fileName: this.export_name,
        columns: columnCustome,
      });
    },
    cekdata() {
      console.log("filed : ", this.field_xlsx);
    },
    moment,

    asexportExcel() {

      const workbook = new ExcelJS.Workbook();
      const filename = "sheet";

      let worksheet = workbook.addWorksheet(filename, {
        
      });
      // workbook.xlsx.writeFile("fileName");

      worksheet.getCell("A6:A7").alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.mergeCells("A6:A7");
      const cellStationId = worksheet.getCell("A6");
      cellStationId.value = "Periode";

      worksheet.mergeCells("B6:B7");
      worksheet.getCell("B6:B7").alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      const cellStationName = worksheet.getCell("B6");
      cellStationName.value = "Nama File";

      // worksheet.mergeCells("C6:C7");
      // worksheet.getCell("C6:C7").alignment = {
      //   horizontal: "center",
      //   vertical: "middle",
      // };
      // const cellPeriode = worksheet.getCell("C6");
      // cellPeriode.value = "Initial Time";
      
      /**
       * start*
      **/

      const headerRowHasil = worksheet.getRow(1);
      headerRowHasil.height = 30;
      worksheet.mergeCells("A1:AH1");    
      headerRowHasil.getCell(1).value = "Hasil Monitoring Data ECMWF  " + moment(this.PERIODE[0]).format("DD MMMM  YYYY") +" - " + moment(this.PERIODE[1]).format("DD MMMM  YYYY");
      headerRowHasil.getCell(1).font = {
        name: "Arial Black",
        size: 16,
        bold: true,
        color: "#000",
      };
      headerRowHasil.getCell(1).alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal = worksheet.getCell("C6");
      worksheet.mergeCells("C6:AG6");
      worksheet.getCell("C6:AG6").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      cellTanggal.value = "Tanggal";
      worksheet.getRow(6).eachCell((cell) =>
        Object.assign(cell, {
          border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          },
        })
      );

      const cellTanggal1 = worksheet.getCell("C7");
      worksheet.getCell("C7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("C7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      const cellTanggal2 = worksheet.getCell("D7");
      worksheet.getCell("D7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("D7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal3 = worksheet.getCell("E7");
      worksheet.getCell("E7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("E7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal4 = worksheet.getCell("F7");
      worksheet.getCell("F7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("F7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal5 = worksheet.getCell("G7");
      worksheet.getCell("G7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("G7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal6 = worksheet.getCell("H7");
      worksheet.getCell("H7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("H7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal7 = worksheet.getCell("I7");
      worksheet.getCell("I7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("I7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal8 = worksheet.getCell("J7");
      worksheet.getCell("J7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("J7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal9 = worksheet.getCell("K7");
      worksheet.getCell("K7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("K7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal10 = worksheet.getCell("L7");
      worksheet.getCell("L7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("L7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal11 = worksheet.getCell("M7");
      worksheet.getCell("M7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("M7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal12 = worksheet.getCell("N7");
      worksheet.getCell("N7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("N7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal13 = worksheet.getCell("O7");
      worksheet.getCell("O7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("O7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal14 = worksheet.getCell("P7");
      worksheet.getCell("P7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("P7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal15 = worksheet.getCell("Q7");
      worksheet.getCell("Q7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("Q7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal16 = worksheet.getCell("R7");
      worksheet.getCell("R7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("R7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal17 = worksheet.getCell("S7");
      worksheet.getCell("S7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("S7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal18 = worksheet.getCell("T7");
      worksheet.getCell("T7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("T7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal19 = worksheet.getCell("U7");
      worksheet.getCell("U7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("U7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal20 = worksheet.getCell("V7");
      worksheet.getCell("V7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("V7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal21 = worksheet.getCell("W7");
      worksheet.getCell("W7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("W7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal22 = worksheet.getCell("X7");
      worksheet.getCell("X7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("X7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal23 = worksheet.getCell("Y7");
      worksheet.getCell("Y7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("Y7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal24 = worksheet.getCell("Z7");
      worksheet.getCell("Z7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("Z7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal25 = worksheet.getCell("AA7");
      worksheet.getCell("AA7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AA7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal26 = worksheet.getCell("AB7");
      worksheet.getCell("AB7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AB7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal27 = worksheet.getCell("AC7");
      worksheet.getCell("AC7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AC7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal28 = worksheet.getCell("AD7");
      worksheet.getCell("AD7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AD7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal29 = worksheet.getCell("AE7");
      worksheet.getCell("AE7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AE7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal30 = worksheet.getCell("AF7");
      worksheet.getCell("AF7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AF7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const cellTanggal31 = worksheet.getCell("AG7");
      worksheet.getCell("AG7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("AG7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      // const cellTanggal31 = worksheet.getCell("AH7");
      // worksheet.getCell("AH7").alignment = {
      //   horizontal: "center",
      //   vertical: "middle",
      // };
      // worksheet.getCell("AH7").border = {
      //   top: { style: "thin" },
      //   left: { style: "thin" },
      //   bottom: { style: "thin" },
      //   right: { style: "thin" },
      // };

      worksheet.getRow(6).eachCell((cell) =>
        Object.assign(cell, {
          border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          },
        })
      );

      
      worksheet.getRow(7).eachCell((cell) =>
        Object.assign(cell, {
          border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          },
        })
      );
      worksheet.getCell("AH6:AH7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.mergeCells("AH6:AH7");
      worksheet.getCell("AH6:AH7").alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.getCell("AH6").value = "%";
      
      worksheet.columns = [
        { key: "periode", width: 15, alignment: "center", wrapText: true },
        { key: "nama_file", width: 20, wrapText: true },

        { key: "01", width: 7 },
        { key: "02", width: 7 },
        { key: "03", width: 7 },
        { key: "04", width: 7 },
        { key: "05", width: 7 },
        { key: "06", width: 7 },
        { key: "07", width: 7 },
        { key: "08", width: 7 },
        { key: "09", width: 7 },
        { key: "10", width: 7 },
        { key: "11", width: 7 },
        { key: "12", width: 7 },
        { key: "13", width: 7 },
        { key: "14", width: 7 },
        { key: "15", width: 7 },
        { key: "16", width: 7 },
        { key: "17", width: 7 },
        { key: "18", width: 7 },
        { key: "19", width: 7 },
        { key: "20", width: 7 },
        { key: "21", width: 7 },
        { key: "22", width: 7 },
        { key: "23", width: 7 },
        { key: "24", width: 7 },
        { key: "25", width: 7 },
        { key: "26", width: 7 },
        { key: "27", width: 7 },
        { key: "28", width: 7 },
        { key: "29", width: 7 },
        { key: "30", width: 7 },
        { key: "31", width: 7 },
        { key: "percent", width: 10, style: { numFmt: '#,#0.00"%"' } },
      ];

      worksheet.addRows(this.rows);
      const rowCount = worksheet.rowCount;
      for (let i = 6; i <= rowCount; i++) {
        const row = worksheet.getRow(7 + i);
        const data = worksheet.getRow(i);

        for (let index = 1; index <= 34; index++) {
          data.getCell(index).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };

          data.getCell(index).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        }
      }

      worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };

      worksheet.getCell(`A${rowCount}`).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.mergeCells(`A${rowCount + 4}:B${rowCount + 4}`);
      worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";
      worksheet.getCell(`A${rowCount + 4}`).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.mergeCells(`A${rowCount + 5}:B${rowCount + 5}`);
      worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
      worksheet.getCell(`A${rowCount + 5}`).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.mergeCells(`A${rowCount + 6}:B${rowCount + 6}`);
      worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
      worksheet.getCell(`A${rowCount + 6}`).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };

      worksheet.getColumn(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.getColumn(3).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.getColumn(4).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };

      worksheet.mergeCells(`AC${rowCount + 5}:AF${rowCount + 5}`);
      worksheet.getCell(`AC${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
      worksheet.getCell(`AC${rowCount + 5}`).alignment = {
        horizontal: "left",
        vertical: "middle",
        wrapText: true,
      };

      worksheet.mergeCells(`AC${rowCount + 6}:AF${rowCount + 6}`);
      worksheet.getCell(`AC${rowCount + 6}`).value = "Pembuat Laporan, ";
      worksheet.getCell(`AC${rowCount + 6}`).alignment = {
        horizontal: "left",
        vertical: "middle",
        wrapText: true,
      };

      cellTanggal1.value = "1";
      cellTanggal2.value = "2";
      cellTanggal3.value = "3";
      cellTanggal4.value = "4";
      cellTanggal5.value = "5";
      cellTanggal6.value = "6";
      cellTanggal7.value = "7";
      cellTanggal8.value = "8";
      cellTanggal9.value = "9";
      cellTanggal10.value = "10";
      cellTanggal11.value = "11";
      cellTanggal12.value = "12";
      cellTanggal13.value = "13";
      cellTanggal14.value = "14";
      cellTanggal15.value = "15";
      cellTanggal16.value = "16";
      cellTanggal17.value = "17";
      cellTanggal18.value = "18";
      cellTanggal19.value = "19";
      cellTanggal20.value = "20";
      cellTanggal21.value = "21";
      cellTanggal22.value = "22";
      cellTanggal23.value = "23";
      cellTanggal24.value = "24";
      cellTanggal25.value = "25";
      cellTanggal26.value = "26";
      cellTanggal27.value = "27";
      cellTanggal28.value = "28";
      cellTanggal29.value = "29";
      cellTanggal30.value = "30";
      cellTanggal31.value = "31";
      /**
       * FOOTER *
       * */
      

      // // FOOTRER
      workbook.xlsx
        .writeBuffer()
        .then((buffer) => saveAs(new Blob([buffer]), `${this.export_name}.xlsx`))
        .catch((err) => console.log("Error writing excel export", err));
    },
  },
  data: function () {
    return {
      columns: [],
      rows: [],
      searchTerm: "",
      export_name: this.ExportExcelName,
      export_xlsx: this.ExportExcelName + ".xlsx",
      field_xlsx: {},
      balaidata: [],
      sumberdata: [],
    };
  },
  watch: {
    columns(val) {
      var columnCustome = {};
      for (var i in val) {
        var infoCol = val[i];
        var label = String(infoCol.label);
        if (infoCol.field == "progress") {
          label = "Progress %";
        }
        if (infoCol.field != "station_id" && infoCol.field != "station_name" && infoCol.field != "periode" && infoCol.field != "progress") {
          columnCustome["'" + label + "'"] = infoCol.field;
        } else {
          columnCustome[label] = infoCol.field;
        }
      }
      this.field_xlsx = columnCustome;
      // var json_fields = {}

      //     var len_data = val.length;
      //     // day 28 = 32
      //     if(len_data < 33){
      //         json_fields = {
      //             "Station ID": "station_id",
      //             "Station Name": "station_name",
      //             "Periode": "periode",
      //             "01": "01",
      //             "02": "02",
      //             "03": "03",
      //             "04": "04",
      //             "05": "05",
      //             "06": "06",
      //             "07": "07",
      //             "08": "08",
      //             "09": "09",
      //             "10": "10",
      //             "11": "11",
      //             "12": "12",
      //             "13": "13",
      //             "14": "14",
      //             "15": "15",
      //             "16": "16",
      //             "17": "17",
      //             "18": "18",
      //             "19": "19",
      //             "20": "20",
      //             "21": "21",
      //             "22": "22",
      //             "23": "23",
      //             "24": "24",
      //             "25": "25",
      //             "26": "26",
      //             "27": "27",
      //             "28": "28",
      //             "Progress %": "progress"
      //         }
      //     // day 29 = 33
      //     }else if(len_data < 34){
      //         json_fields = {
      //             "Station ID": "station_id",
      //             "Station Name": "station_name",
      //             "Periode": "periode",
      //             "01": "01",
      //             "02": "02",
      //             "03": "03",
      //             "04": "04",
      //             "05": "05",
      //             "06": "06",
      //             "07": "07",
      //             "08": "08",
      //             "09": "09",
      //             "10": "10",
      //             "11": "11",
      //             "12": "12",
      //             "13": "13",
      //             "14": "14",
      //             "15": "15",
      //             "16": "16",
      //             "17": "17",
      //             "18": "18",
      //             "19": "19",
      //             "20": "20",
      //             "21": "21",
      //             "22": "22",
      //             "23": "23",
      //             "24": "24",
      //             "25": "25",
      //             "26": "26",
      //             "27": "27",
      //             "28": "28",
      //             "29": "29",
      //             "Progress %": "progress"
      //         }

      //     // day 30 = 34
      //     }else if(len_data < 35){
      //         json_fields = {
      //             "Station ID": "station_id",
      //             "Station Name": "station_name",
      //             "Periode": "periode",
      //             "01": "01",
      //             "02": "02",
      //             "03": "03",
      //             "04": "04",
      //             "05": "05",
      //             "06": "06",
      //             "07": "07",
      //             "08": "08",
      //             "09": "09",
      //             "10": "10",
      //             "11": "11",
      //             "12": "12",
      //             "13": "13",
      //             "14": "14",
      //             "15": "15",
      //             "16": "16",
      //             "17": "17",
      //             "18": "18",
      //             "19": "19",
      //             "20": "20",
      //             "21": "21",
      //             "22": "22",
      //             "23": "23",
      //             "24": "24",
      //             "25": "25",
      //             "26": "26",
      //             "27": "27",
      //             "28": "28",
      //             "29": "29",
      //             "30": "30",
      //             "Progress %": "progress"
      //         }

      //     // day 31 = 35
      //     }else if(len_data < 36){
      //         json_fields = {
      //             "Station ID": "station_id",
      //             "Station Name": "station_name",
      //             "Periode": "periode",
      //             "01": "01",
      //             "02": "02",
      //             "03": "03",
      //             "04": "04",
      //             "05": "05",
      //             "06": "06",
      //             "07": "07",
      //             "08": "08",
      //             "09": "09",
      //             "10": "10",
      //             "11": "11",
      //             "12": "12",
      //             "13": "13",
      //             "14": "14",
      //             "15": "15",
      //             "16": "16",
      //             "17": "17",
      //             "18": "18",
      //             "19": "19",
      //             "20": "20",
      //             "21": "21",
      //             "22": "22",
      //             "23": "23",
      //             "24": "24",
      //             "25": "25",
      //             "26": "26",
      //             "27": "27",
      //             "28": "28",
      //             "29": "29",
      //             "30": "30",
      //             "31": "31",
      //             "Progress %": "progress"
      //         }
      //     }
      //     console.log("JSON : ",json_fields);
      //    this.field_xlsx = json_fields;
    },
  },
};
</script>

<style>
.icons-visual {
  font-size: 15px !important;
  display: block;
  text-align: center;
  font-weight: bold;
  margin: 5px -15px 8px 0px;
}

.monitor table.vgt-table td {
  padding: 10px !important;
  vertical-align: inherit !important;
}

.monitor .vgt-left-align {
  padding: 10px !important;
  vertical-align: inherit !important;
}

.monitor table.vgt-table {
  font-size: 12px !important;
}

/* button {
  padding: 10px 20px;
  border: 1px solid #ddd;
  color: #333;
  background-color: green;
  border-radius: 4px;
  font-size: 24px;
  font-weight: bold;
  font-family: "微软雅黑", arail;
  cursor: pointer;
} */
</style>
