<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card>
          <b-row>
            
            <b-col sm="4" xl="4" md="4">
              <label>*Date Range</label>
              <a-range-picker
                class="w-100"
                @change="onChangeRangeDate"
                :value="DATE_RANGE_SELECTED != null && DATE_RANGE_SELECTED != '' ? [moment(DATE_RANGE_SELECTED[0], 'YYYY-MM-DD'), moment(DATE_RANGE_SELECTED[1], 'YYYY-MM-DD')] : DATE_RANGE_SELECTED"
              />
            </b-col>

            <b-col sm="4" xl="2" md="2">
              <label>Balai</label>
              <v-select v-model="BALAI" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL" :options="BALAI_STORE" class="select-size-sm" />
            </b-col>

            <b-col sm="4" xl="5" md="5">
              <label>*Stasiun</label>
              <v-select v-model="STATION" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="LABEL" :options="STATION_STORE" class="select-size-sm " /> <!-- tambahkan class vs--single agar heightnya sama-->
            </b-col>

            <b-col sm="4 mt-2 pad2" xl="1" md="1" class="float-right">
              <b-form-group>
                <b-form inline id="select-form">
                  <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="">Filter</b-button>
                </b-form>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
    <a-spin :spinning="isLoading">
      <div class="row">
        <div class="col-xl-12">
          <b-card>
            <b-row v-if="showTable">
              <div style="width: 100%; overflow: auto">
                <!-- <DinamicTable :PERIODE="DATE_SELECTED" :RESOURCE="RESOURCE" :BALAI="BALAI" :DATAS="RAW_DATA_TABLE" :ExportExcelName="ExportExcelName" @selectionChanged="selectionChanged" @onRowClick="onRowClick" ref="dinamicTableComponent">
                </DinamicTable> -->
                <rason-table :PERIODE="DATE_RANGE_SELECTED" :DATAS="RAW_DATA_TABLE" :ExportExcelName="ExportExcelName" @selectionChanged="selectionChanged" @onRowClick="onRowClick" ref="dinamicTableComponent">
                </rason-table>
              </div>
            </b-row>
          </b-card>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import vSelect from "vue-select";

import RasonTable from "./mdicomponent/RasonTable.vue"

import {
  BOverlay,
  BTable,
  BAvatar,
  BBadge,
  BPagination,
  BInputGroupAppend,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BButton,
  BButtonGroup,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup,
  BFormTextarea,
  BPopover,
  BModal,
  VBModal,
  BForm,
  AlertPlugin,
} from "bootstrap-vue";

import metadata from "@/api/metadata";
import monitoring from "@/api/monitoring";
import moment from "moment";

export default {
  components: {
    RasonTable,
    vSelect,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroupAppend,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BButton,
    BButtonGroup,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BPopover,
    BModal,
    VBModal,
    BForm,
    AlertPlugin,
  },
  data() {
    return {
      ExportExcelName: "BMKGSatu Monitoring",
      isLoading: false,
      showTable: false,
      DATE_RANGE_SELECTED: [],
      PRODUK:  "",
      PRODUK_STORE: [
        { KEY : "1", LABEL : "ANALISIS", CODE : "ANALISIS"},
        { KEY : "2", LABEL : "FORECAST", CODE : "FORECAST"}
      ],
      BALAI: "",
      BALAI_STORE: [],
      STATION: "",
      STATION_STORE: [],
      FIX_STATION: "",
      FIX_STATION_STORE: [],
      PROVINSI: "",
      PROVINSI_STORE: [],
      KOTAKABUPATEN: "",
      KOTAKABUPATEN_STORE: [],
      RESOURCE: "",
      RESOURCE_STORE: [
        ///FDIH
        // { KEY : "3", LABEL : "FKLIM71", CODE : "FKIKLIM71"},
        // { KEY : "4", LABEL : "IKLIM MIKRO", CODE : "IKLIM_MIKRO"},
        // { KEY : "5", LABEL : "AGM 1A", CODE : "AGM1A"},
        // { KEY : "6", LABEL : "AGM 1B", CODE : "AGM1B"},
        // { KEY : "7", LABEL : " PSYCHOMETER ASSMAN", CODE : "PSYCHROMETER_ASSMAN"},
        // { KEY : "8", LABEL : "SUHU TANAH", CODE : "SUHU_TANAH"},
        // { KEY : "9", LABEL : "OP PENGUAPAN", CODE : "OP_PENGUAPAN"},
        // { KEY : "10", LABEL : "PIECE PENGUAPAN", CODE : "PICHE_PENGUAPAN"},
        // { KEY : "11", LABEL : "LYSIMETER", CODE : "LYSIMETER"},
        // { KEY : "12", LABEL : "GUNBELANI", CODE : "GUNBELLANI"},
        // ///PIAS atau FORM
        // { KEY : "13", LABEL : "LAMA PENYINARAN", CODE : "LAMA_PENYINARAN"},
        // { KEY : "14", LABEL : "AKTINOGRAPH", CODE : "AKTINOGRAPH"},
        // { KEY : "15", LABEL : "THERMOHIGOGRAPH", CODE : "THERMOHIGOGRAPH"},
        // { KEY : "16", LABEL : "BAROGRAPH", CODE : "BAROGRAPH"},
        // { KEY : "17", LABEL : "PERAWANAN", CODE : "PERAWANAN"},
        // { KEY : "18", LABEL : "FORM HUJAN", CODE : "FORM_HUJAN"},
        // { KEY : "19", LABEL : "HUJAN HELMAN", CODE : "HUJAN_HELMAN"},
        // { KEY : "20", LABEL : "KELEMBAPAN TANAH", CODE : "KELEMBAPAN_TANAH"},
        ///METEO
        // { KEY: "1", LABEL: "ME 45", CODE: "ME_45" },
        // { KEY: "22", LABEL: "METAR SPECI", CODE: "METAR_SPECI" },
        // { KEY: "21", LABEL: "PIBAL", CODE: "PIBAL" },
        // { KEY: "2", LABEL: "SINOPTIK", CODE: "ME_48" },
        // { KEY : "23", LABEL : "PILOT BALON", CODE : "PILOT BALON"},
        // { KEY : "24", LABEL : "PETIR", CODE : "PETIR"},
        // { KEY : "25", LABEL : "MAGNET", CODE : "MAGNET"},
      ],

      BALAI_SELECTED: "",
      PROVINSI_SELECTED: "",
      KOTAKABUPATEN_SELECTED: "",
      RESOURCE_SELECTED: "",
      DATE_SELECTED: "",
      RAW_DATA_TABLE: [],
      pageOptions: [5, 10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      fields: [],
      filter_data: "",
      perPage: 10,
      columns: [],
      rows: [],
      DATA_FLAG: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "00:30",
        "01:30",
        "02:30",
        "03:30",
        "04:30",
        "05:30",
        "06:30",
        "07:30",
        "08:30",
        "09:30",
        "10:30",
        "11:30",
        "12:30",
        "13:30",
        "14:30",
        "15:30",
        "16:30",
        "17:30",
        "18:30",
        "19:30",
        "20:30",
        "21:30",
        "22:30",
        "23:30",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
      ROW_HIDE: ["region", "propinsi", "kabupaten", "current_latitude", "current_longitude", "count_available", "count_not_available"],
    };
  },
  mounted() {
    this.optionRegion();
    this.listAllStation();
  },
  watch: {
    RESOURCE(VALUES) {
      this.RESOURCE_SELECTED = VALUES.KEY;
    },
    BALAI(VALUES) {
      this.BALAI_SELECTED = VALUES.KEY;
      if (VALUES) {
        this.listAllStation(VALUES.KEY);
      }
      else {
        this.listAllStation(null);
      }
      // this.PROVINSI = [
      //   {
      //     KEY: "",
      //     LABEL: "PILIH PROVINSI",
      //   },
      // ];
      // this.PROVINSI_SELECTED = "";
    },
    PROVINSI(VALUES) {
      this.PROVINSI_SELECTED = VALUES.KEY;
      this.listKabupaten(VALUES.KEY);
      this.KOTAKABUPATEN = [
        {
          KEY: "",
          LABEL: "PILIH KOTA/KABUPATEN",
        },
      ];
      this.KOTAKABUPATEN_SELECTED = "";
    },
    KOTAKABUPATEN(VALUES) {
      this.KOTAKABUPATEN_SELECTED = VALUES.KEY;
    },
  },
  methods: {
    moment,
    onChangeRangeDate(date, dateString) {
      // this.DATE_RANGE_SELECTED = dateString;
      if (date.length === 0) {
        this.DATE_RANGE_SELECTED = []
      }
      else {
        this.DATE_RANGE_SELECTED = dateString;
      }
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },
    onChange(date, dateString) {
      this.DATE_SELECTED = dateString;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFetch() {
      // if (this.RESOURCE_SELECTED == "" || this.BALAI_SELECTED == "" || this.DATE_SELECTED == "") {
      //   this.$swal({
      //     title: "ERROR",
      //     text: "Silahkan Lengkapi Filter",
      //     icon: "error",
      //     customClass: {
      //       confirmButton: "btn btn-danger",
      //     },
      //     buttonsStyling: false,
      //   });
      //   return;
      // }
      // if (this.RESOURCE_SELECTED == 21 || this.RESOURCE_SELECTED == "21") {
      //   this.ROW_HIDE = [
      //     "region",
      //     "propinsi",
      //     "kabupaten",
      //     "current_latitude",
      //     "current_longitude",
      //     "count_available",
      //     "count_not_available",
      //     "01:00",
      //     "02:00",
      //     "03:00",
      //     "04:00",
      //     "05:00",
      //     "07:00",
      //     "08:00",
      //     "09:00",
      //     "10:00",
      //     "11:00",
      //     "13:00",
      //     "14:00",
      //     "15:00",
      //     "16:00",
      //     "17:00",
      //     "19:00",
      //     "20:00",
      //     "21:00",
      //     "22:00",
      //     "23:00",
      //     "24:00",
      //   ];
      // } else {
      //   this.ROW_HIDE = ["region", "propinsi", "kabupaten", "current_latitude", "current_longitude", "count_available", "count_not_available"];
      // }
      this.getMonitoring();
    },
    async getMonitoring() {
      this.isLoading = true;
      this.showTable = false;
      this.ExportExcelName = "BMKGSatu Monitoring_Rason_" + this.DATE_RANGE_SELECTED[0] +"-"+this.DATE_RANGE_SELECTED[1];
      try {
        // const { data } = await monitoring.getMonitoring(this.RESOURCE_SELECTED, this.BALAI_SELECTED, this.PROVINSI_SELECTED, this.KOTAKABUPATEN_SELECTED, this.DATE_SELECTED);
        let stations = ''
        for (var idx in this.STATION) {
          stations += this.STATION[idx].CODE + ','
        }
        stations = stations.substring(0, stations.length - 1) 
        console.log('getMonitoring', this.STATION, stations)
        const { data } = await monitoring.getMonitoringRason(this.DATE_RANGE_SELECTED, stations);
        this.FIX_STATION = this.STATION
        this.FIX_STATION_STORE = this.STATION_STORE
        console.log("request datas : ", data, this.FIX_STATION);
        var datas = data;
        var cols = [];
        var raws = [];

        // cols = [
        //   {label: 'Stasiun', field: 'station'},
        //   {label: 'Nama File', field: 'name_file'},
        //   {label: '01 | 00', field: '01_00'},
        //   {label: '01 | 12', field: '01_12'},
        //   {label: '02 | 00', field: '02_00'},
        //   {label: '02 | 12', field: '02_12'},
        //   {label: '03 | 00', field: '03_00'},
        //   {label: '03 | 12', field: '03_12'},
        //   {label: '04 | 00', field: '04_00'},
        //   {label: '04 | 12', field: '04_12'},
        //   {label: '05 | 00', field: '05_00'},
        //   {label: '05 | 12', field: '05_12'},
        //   {label: '06 | 00', field: '06_00'},
        //   {label: '06 | 12', field: '06_12'},
        //   {label: '07 | 00', field: '07_00'},
        //   {label: '07 | 12', field: '07_12'},
        //   {label: '08 | 00', field: '08_00'},
        //   {label: '08 | 12', field: '08_12'},
        //   {label: '09 | 00', field: '09_00'},
        //   {label: '09 | 12', field: '09_12'},
        //   {label: '10 | 00', field: '10_00'},
        //   {label: '10 | 12', field: '10_12'},
        //   {label: '11 | 00', field: '11_00'},
        //   {label: '11 | 12', field: '11_12'},
        //   {label: '12 | 00', field: '12_00'},
        //   {label: '12 | 12', field: '12_12'},
        //   {label: '13 | 00', field: '13_00'},
        //   {label: '13 | 12', field: '13_12'},
        //   {label: '14 | 00', field: '14_00'},
        //   {label: '14 | 12', field: '14_12'},
        //   {label: '15 | 00', field: '15_00'},
        //   {label: '15 | 12', field: '15_12'},
        //   {label: '16 | 00', field: '16_00'},
        //   {label: '16 | 12', field: '16_12'},
        //   {label: '17 | 00', field: '17_00'},
        //   {label: '17 | 12', field: '17_12'},
        //   {label: '18 | 00', field: '18_00'},
        //   {label: '18 | 12', field: '18_12'},
        //   {label: '19 | 00', field: '19_00'},
        //   {label: '19 | 12', field: '19_12'},
        //   {label: '20 | 00', field: '20_00'},
        //   {label: '20 | 12', field: '20_12'},
        //   {label: '21 | 00', field: '21_00'},
        //   {label: '21 | 12', field: '21_12'},
        //   {label: '22 | 00', field: '22_00'},
        //   {label: '22 | 12', field: '22_12'},
        //   {label: '23 | 00', field: '23_00'},
        //   {label: '23 | 12', field: '23_12'},
        //   {label: '24 | 00', field: '24_00'},
        //   {label: '24 | 12', field: '24_12'},
        //   {label: '25 | 00', field: '25_00'},
        //   {label: '25 | 12', field: '25_12'},
        //   {label: '26 | 00', field: '26_00'},
        //   {label: '26 | 12', field: '26_12'},
        //   {label: '27 | 00', field: '27_00'},
        //   {label: '27 | 12', field: '27_12'},
        //   {label: '28 | 00', field: '28_00'},
        //   {label: '28 | 12', field: '28_12'},
        //   {label: '29 | 00', field: '29_00'},
        //   {label: '29 | 12', field: '29_12'},
        //   {label: '30 | 00', field: '30_00'},
        //   {label: '30 | 12', field: '30_12'},
        //   {label: '31 | 00', field: '31_00'},
        //   {label: '31 | 12', field: '31_12'},
        //   {label: '%', field: 'percent'},
        // ]
        cols = [
          {label: 'Stasiun', key: 'station', class: 'my-left-border'},
          {label: 'Nama File', key: 'name_file', class: 'my-left-border'},
          {label: '00', key: '01_00', class: 'my-left-border'},
          {label: '12', key: '01_12', class: 'my-left-border'},
          {label: '00', key: '02_00', class: 'my-left-border'},
          {label: '12', key: '02_12', class: 'my-left-border'},
          {label: '00', key: '03_00', class: 'my-left-border'},
          {label: '12', key: '03_12', class: 'my-left-border'},
          {label: '00', key: '04_00', class: 'my-left-border'},
          {label: '12', key: '04_12', class: 'my-left-border'},
          {label: '00', key: '05_00', class: 'my-left-border'},
          {label: '12', key: '05_12', class: 'my-left-border'},
          {label: '00', key: '06_00', class: 'my-left-border'},
          {label: '12', key: '06_12', class: 'my-left-border'},
          {label: '00', key: '07_00', class: 'my-left-border'},
          {label: '12', key: '07_12', class: 'my-left-border'},
          {label: '00', key: '08_00', class: 'my-left-border'},
          {label: '12', key: '08_12', class: 'my-left-border'},
          {label: '00', key: '09_00', class: 'my-left-border'},
          {label: '12', key: '09_12', class: 'my-left-border'},
          {label: '00', key: '10_00', class: 'my-left-border'},
          {label: '12', key: '10_12', class: 'my-left-border'},
          {label: '00', key: '11_00', class: 'my-left-border'},
          {label: '12', key: '11_12', class: 'my-left-border'},
          {label: '00', key: '12_00', class: 'my-left-border'},
          {label: '12', key: '12_12', class: 'my-left-border'},
          {label: '00', key: '13_00', class: 'my-left-border'},
          {label: '12', key: '13_12', class: 'my-left-border'},
          {label: '00', key: '14_00', class: 'my-left-border'},
          {label: '12', key: '14_12', class: 'my-left-border'},
          {label: '00', key: '15_00', class: 'my-left-border'},
          {label: '12', key: '15_12', class: 'my-left-border'},
          {label: '00', key: '16_00', class: 'my-left-border'},
          {label: '12', key: '16_12', class: 'my-left-border'},
          {label: '00', key: '17_00', class: 'my-left-border'},
          {label: '12', key: '17_12', class: 'my-left-border'},
          {label: '00', key: '18_00', class: 'my-left-border'},
          {label: '12', key: '18_12', class: 'my-left-border'},
          {label: '00', key: '19_00', class: 'my-left-border'},
          {label: '12', key: '19_12', class: 'my-left-border'},
          {label: '00', key: '20_00', class: 'my-left-border'},
          {label: '12', key: '20_12', class: 'my-left-border'},
          {label: '00', key: '21_00', class: 'my-left-border'},
          {label: '12', key: '21_12', class: 'my-left-border'},
          {label: '00', key: '22_00', class: 'my-left-border'},
          {label: '12', key: '22_12', class: 'my-left-border'},
          {label: '00', key: '23_00', class: 'my-left-border'},
          {label: '12', key: '23_12', class: 'my-left-border'},
          {label: '00', key: '24_00', class: 'my-left-border'},
          {label: '12', key: '24_12', class: 'my-left-border'},
          {label: '00', key: '25_00', class: 'my-left-border'},
          {label: '12', key: '25_12', class: 'my-left-border'},
          {label: '00', key: '26_00', class: 'my-left-border'},
          {label: '12', key: '26_12', class: 'my-left-border'},
          {label: '00', key: '27_00', class: 'my-left-border'},
          {label: '12', key: '27_12', class: 'my-left-border'},
          {label: '00', key: '28_00', class: 'my-left-border'},
          {label: '12', key: '28_12', class: 'my-left-border'},
          {label: '00', key: '29_00', class: 'my-left-border'},
          {label: '12', key: '29_12', class: 'my-left-border'},
          {label: '00', key: '30_00', class: 'my-left-border'},
          {label: '12', key: '30_12', class: 'my-left-border'},
          {label: '00', key: '31_00', class: 'my-left-border'},
          {label: '12', key: '31_12', class: 'my-left-border'},
          {label: '%', key: 'percent', class: 'my-left-border my-right-border'},
        ]

        let parameter = datas
        for (var i in parameter) {     
          for (var j in parameter[i]) {
            let initTime = datas[i][j]
            let name_station = i + ' - ' + ''
            for (var x in this.FIX_STATION) {
              if (this.FIX_STATION[x].CODE == i) {
                name_station += this.FIX_STATION[x].LABEL
                break;
              }
            }
            let raw = {station:name_station, name_file: j }
            for (var a = 1; a <= 31; a++) {
              raw[this.padZero(a, 2)+"_00"] = ''
              raw[this.padZero(a, 2)+"_12"] = ''
            }
            for (var m in initTime) {
              if (m == '%') {
                raw['percent'] = initTime[m]
              } 
              else {
                let date = m
                let days = date.split('-')
                // raw[days[2]] = initTime[m]
                raw[days[2]+"_00"] = ''+initTime[m]["00"]
                raw[days[2]+"_12"] = ''+initTime[m]["12"]
              } 
            }           
            
            raws.push(raw)
          }
        }
        
        var dataBuild = { DATAS: raws, columns: cols };
        console.log("request datas : ", dataBuild);
        this.RAW_DATA_TABLE = dataBuild;

        this.showTable = true;

        setTimeout(() => {
          this.$refs.dinamicTableComponent.generateTable(this.RAW_DATA_TABLE);
          this.isLoading = false;
        }, 1000);
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },
    padZero(val, n) {
			return `${"0".repeat(n)}${val}`.substr(-n, n);
		},
    sortDataTable() {
      var data = this.RAW_DATA_TABLE;
      var TempRaw = {};
      for (var i in data) {
        var items = data[i];
      }
    },
    async optionRegion() {
      try {
        const { data } = await metadata.getRegionList();
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const dataRegion = {
            KEY: data[i].region_id,
            LABEL: data[i].region_description,
          };
          this.BALAI_STORE.push(dataRegion);
        }
        // this.BALAI = 1
      } catch (e) {
        console.log(e);
      }
    },
    async listPropinsi(region_id) {
      this.PROVINSI_STORE = [];
      try {
        const { data } = await metadata.getPropinsiListByRegion(region_id);
        var _selected = "";
        for (var i = 0; i < data.length; i++) {
          const rowsPropinsi = {
            KEY: data[i].propinsi_id,
            LABEL: data[i].propinsi_name,
          };
          this.PROVINSI_STORE.push(rowsPropinsi);
          _selected = data[i].propinsi_id;
        }
        // this.PROVINSI" = _selected;
      } catch (e) {
        console.log(e);
      }
    },
    async listKabupaten(propinsi_id) {
      this.KOTAKABUPATEN_STORE = [];
      try {
        const { data } = await metadata.getKabupatenListByPropinsi(propinsi_id);
        var _selected = "";
        for (var i = 0; i < data.length; i++) {
          const rowsKabupaten = {
            KEY: data[i].kabupaten_id,
            LABEL: data[i].kabupaten_name,
          };
          this.KOTAKABUPATEN_STORE.push(rowsKabupaten);
          _selected = data[i].kabupaten_id;
        }
        // this.KOTAKABUPATEN = _selected;
      } catch (e) {
        console.log(e);
      }
    },
    async listAllStation(region_id) {
      this.STATION = ""
      this.STATION_STORE = [];
      try {
        const { data } = (region_id) ? await metadata.getStasiunWithWMObyRegion(region_id) : await metadata.getStasiunWithWMObyRegion(null);
        // var _selected = "";
        for (var i = 0; i < data.length; i++) {
          const rowsStation = {
            KEY: data[i].station_id,
            LABEL: data[i].station_name,
            CODE: data[i].station_wmo_id,
          };
          this.STATION_STORE.push(rowsStation);
          // _selected = data[i].kabupaten_id;
        }
        // this.KOTAKABUPATEN = _selected;
      } catch (e) {
        console.log(e);
      }
    },
    onRowClick(params) {
      // console.log(params);
    },
    selectionChanged(params) {
      console.log(params);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style>
.header-box-custome {
  background: #3f9cff;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px;
}

.monitor .vgt-table {
  font-size: 12px !important;
}

[dir="ltr"] .monitor .vgt-table th {
  padding: 5px 25px 5px 5px !important;
}

[dir] .monitor table.vgt-table td {
  padding: 5px 25px 5px 5px !important;
}

[dir] .ant-input {
  height: 36px;
}
</style>
