<template>
  <div>
    <b-tabs>
        <b-tab title="GCAM">
            <MGcam />
        </b-tab>
        <b-tab title="ECWMF" lazy>
            <MEcmwf />
        </b-tab>
        <b-tab title="RASON" lazy>
            <MRason />
        </b-tab>
        <b-tab title="SATELIT HIMAWARI" lazy>
            <MSatelitHimawari />
        </b-tab>
        <b-tab title="RADAR" lazy>
            <MRadar />
        </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from "bootstrap-vue";
import MGcam from "./MGcam.vue";
import MEcmwf from "./MEcmwf.vue";
import MSatelitHimawari from "./MSatelitHimawari.vue";
import MRadar from "./MRadar.vue";
import MRason from "./MRason.vue";
import helper from "@/api/helper";


export default {
  components: {
    BTabs,
    BTab,
    BCard,
    MGcam,
    MEcmwf,
    MRason,
    MSatelitHimawari,
    MRadar
  },
  data() {
    return {
      text : ""
    };
  },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    
  },
};
</script>
